import Menu, { MenuItem } from "../../../../components/Menu";
import React from "react";
import { useString as s } from "../../../../components/StringProvider";
import PropTypes from "prop-types";
import Icon from "../../../../components/Icon";
import Dropdown from "../../../../components/Dropdown";
import { Operations } from "../AccessManagementActions";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import NewButton from "../../../../components/NewButton";
import { startAccessManagementOperation } from "../../../../store/actions/users";
import UserStatuses from "../../../../utils/user-statuses";
import { selectIsCuvamaUser } from "../../../../store/reducers";

const UserActionsDropdown = ({
  userId,
  status,
  startAccessManagementOperation,
  isCuvamaUser,
  isExternalUser
}) => {
  const resetPasswordText = s(
    "accessManagement.page.users.resetPassword",
    "Reset password"
  );
  const deactivateUserText = s(
    "accessManagement.page.users.deactivateUser",
    "Deactivate user"
  );
  const reactivateUserText = s(
    "accessManagement.page.users.reactivateUser",
    "Reactivate user"
  );
  const generatePasswordText = s(
    "accessManagement.page.users.generatePassword",
    "Generate Password"
  );
  const convertToUserText = s(
    "accessManagement.page.users.convertToUser.menuItem",
    "Convert to user"
  );
  const actionsText = s("accessManagement.page.users.name", "Actions");
  const showResetPasswordAction = false;

  const onClick = (e) => {
    const operationId = e.key;
    startAccessManagementOperation({ operationId, userId });
  };

  const renderMenu = () => (
    <Menu onClick={onClick}>
      {showResetPasswordAction && (
        <>
          <MenuItem
            key={Operations.RESET_PASSWORD}
            icon={<Icon name={"reset"} />}
          >
            {resetPasswordText}
          </MenuItem>
          <Menu.Divider />
        </>
      )}
      {status !== UserStatuses.DEACTIVATED && (
        <MenuItem
          key={Operations.DEACTIVATE_USER}
          icon={<Icon name={"deactivateUser"} />}
        >
          {deactivateUserText}
        </MenuItem>
      )}
      {status === UserStatuses.DEACTIVATED && (
        <MenuItem
          key={Operations.REACTIVATE_USER}
          icon={<Icon name={"reactivateUser"} />}
        >
          {reactivateUserText}
        </MenuItem>
      )}
      {status !== UserStatuses.DEACTIVATED && isCuvamaUser && (
        <MenuItem
          key={Operations.GENERATE_PASSWORD}
          icon={<Icon name={"reactivateUser"} />}
        >
          {generatePasswordText}
        </MenuItem>
      )}
      {isExternalUser && isCuvamaUser && (
        <MenuItem
          key={Operations.CONVERT_TO_USER}
          icon={<Icon name={"reactivateUser"} />}
        >
          {convertToUserText}
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <Dropdown
      overlay={renderMenu()}
      placement={"bottomLeft"}
      trigger={["click"]}
    >
      <NewButton
        type={"iconSecondary"}
        className="ant-dropdown-link"
        tooltip={actionsText}
        onClick={(e) => e.preventDefault()}
        data-cy={"user-actions-dropdown"}
      >
        <Icon name={"dropdown"} size={"medium"} colour={"gray4"} />
      </NewButton>
    </Dropdown>
  );
};

UserActionsDropdown.propTypes = {
  userId: PropTypes.string.isRequired,
  status: PropTypes.oneOf(UserStatuses.ALL),
  isExternalUser: PropTypes.bool,
  startAccessManagementOperation: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isCuvamaUser: selectIsCuvamaUser(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startAccessManagementOperation
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UserActionsDropdown
);
