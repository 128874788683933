import { bindActionCreators, compose } from "redux";
import { convertToUser } from "../../../../store/actions/users";
import { connect } from "react-redux";
import React, { useState } from "react";
import { useString as s } from "../../../../components/StringProvider";
import PropTypes from "prop-types";
import {
  selectRequestError,
  selectRequestState
} from "../../../../store/reducers";
import NewButton from "../../../../components/NewButton";
import actionTypes from "../../../../store/actionTypes";
import useLoadingState from "../../../../utils/use-loading-state";
import ButtonGroup from "../../../../components/ButtonGroup";
import { Modal } from "../../../../components";
import Notification from "../../../../components/Notification";

const ConvertToUserModal = ({
  userId,
  loadingState,
  error,
  onClose,
  convertToUser
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const submitText = s(
    "accessManagement.page.users.convertToUser.submit",
    "Convert to User"
  );
  const cancelText = s(
    "accessManagement.page.users.convertToUser.cancel",
    "Cancel"
  );
  const errorText = s(
    "accessManagement.page.users.convertToUser.error",
    "Failed to convert user"
  );
  const successText = s(
    "accessManagement.page.users.convertToUser.success",
    "User was successfully converted"
  );

  const onSubmit = () => {
    setIsLoading(true);
    convertToUser({ userId });
  };

  useLoadingState(
    loadingState,
    () => {
      onClose();
      Notification.success(successText);
      setIsLoading(false);
    },
    () => {
      if (error) {
        Notification.error(errorText);
      }
      setIsLoading(false);
    }
  );

  return (
    <Modal
      title={s(
        "accessManagement.popup.convertToUser.header",
        "Convert to User"
      )}
      open={true}
      onCancel={onClose}
    >
      <ButtonGroup>
        <NewButton
          type={"submit"}
          onClick={onSubmit}
          data-cy={"convert-to-user"}
          loading={isLoading}
        >
          {submitText}
        </NewButton>
        <NewButton
          type={"secondary"}
          onClick={onClose}
          data-cy={"dont-convert-to-user"}
        >
          {cancelText}
        </NewButton>
      </ButtonGroup>
    </Modal>
  );
};

ConvertToUserModal.propTypes = {
  userId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(
    state,
    actionTypes.USERS_CONVERT_TO_USER_REQUEST
  ),
  error: selectRequestError(state, actionTypes.USERS_CONVERT_TO_USER_REQUEST)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      convertToUser
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ConvertToUserModal
);
